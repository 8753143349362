@import '/src/styles/functions/@mixin-module';
@import '/src/styles/colors/primary.scss';

.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  padding: 24px 20px 40px;

  &_TwoColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    & > * {
      width: 100%;
      min-width: 300px;
    }
  }

  &_Dropdown {
    [class$='-control'] {
      border-radius: 8px;
    }
    [class$='-ValueContainer'] {
      padding: 6px 10px;
    }
  }

  &_DeleteButton {
    background-color: error(50);
    color: error(700);
    border: none;
    &:hover {
      background-color: error(700);
    }
  }

  &_ButtonContainer {
    display: flex;
    gap: 16px;
  }

  @media screen and (min-width: 768px) {
    &_TwoColumn {
      flex-direction: row;
    }
    // &_ButtonContainer {
    //   flex-direction: row;
    // }
  }
}

.more_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
  box-shadow: 0px 8px 16px -4px rgba(16, 24, 40, 0.24);
  background-color: #fff;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  &__heading {
    color: var(
      --neutral-colors-Gray-neutral-900,
      var(--colors-gray-light-mode-900, #101828)
    );
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.Container_background_color {
  background: linear-gradient(180deg, #d0d4dd 0%, #e4e7ec 16.15%, #e4e7ec 100%);
  padding: 3px;
  border-radius: 8px;
}
.customInstructionsArea {
  min-height: 120px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  display: block !important;
  resize: none !important;
}

.rotate {
  transform: rotate(180deg);
}
