.drop_option {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  background: var(--Colors-Base-white, #fff);
  padding: 6px 16px;
  span {
    font-family: 'Poppins' !important;
  }

  &:hover {
    background: #f8f9fa;
  }
  .dropdown-menu .show {
    transform: translate(-164px, -10px) !important;
  }
  & > div {
    transform: translate(-164px, -10px) !important;
  }
}

.rounded_plus_btn {
  background-color: #1d2939;
  border-radius: 100%;
  position: fixed;
  bottom: 10vh;
  right: 4vw;
  width: 65px;
  height: 65px;
  color: white;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    background-color: #1d2939 !important;
    color: white !important;
  }
  button {
    background-color: #1d2939;
    height: 56px;
    width: 56px;
    border-radius: 100%;
    &:active,
    &:visited,
    &:focus,
    &:focus-visible,
    &:visited,
    &:focus-within,
    &:target {
      background-color: #1d2939;
      height: 56px;
      width: 56px;
      border-radius: 100%;
      color: white;
    }
    & > .btn.show {
      color: white !important;
      background: #1d2939 !important;
    }
  }

  div {
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-mode-200, #eaecf0);
    background: var(--Colors-Base-white, #fff);
    box-shadow: 0px 12px 24px -8px rgba(16, 24, 40, 0.2);
    margin-right: 1rem;
    transform: translate(-105px, -66px) !important;
  }
}

.list_item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  //   background: var(--Colors-Base-white, #fff);
  span {
    font-family: 'Poppins' !important;
  }
}
.list_item_nested {
  display: flex;
  align-items: start;
  gap: 10px;
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  span {
    font-family: 'Poppins' !important;
  }
}

.plus_icon {
  height: 30px;
  width: 30px;
  transition: transform 0.3s ease;
}

[aria-expanded='true'] .plus_icon {
  transform: rotate(-45deg);
}

.description {
  display: flex;
  font-size: 12px;
  font-family: 'DM Sans';
  font-weight: 400;
  line-height: 16px;
  margin-top: 5px;
}
