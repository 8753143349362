@import './styles/variables/colors';
@import './styles/base/fonts';
@import './styles/base/typography';
@import './styles/functions/@mixin-module';
@import 'react-toastify/dist/ReactToastify.css';

::-webkit-scrollbar {
  max-height: 3px;
  width: 3px;
  background-color: font_clr(l-grey);
}
.dashboard-sidebar::-webkit-scrollbar {
  width: 1px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // background-image: -webkit-gradient(
  //   linear,
  //   left bottom,
  //   left top,
  //   color-stop(0.44, rgb(122, 153, 217)),
  //   color-stop(0.72, rgb(73, 125, 189)),
  //   color-stop(0.86, font_clr(logo_primary_color))
  // );
  background: #00a7b7;
}
input[type='password'] {
  &::placeholder {
    font-size: 16px;
    color: #667085;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: calc(100vw - 224px);
    padding: 0;
  }
}
.error {
  color: font_clr(error) !important;
  font-size: 12px;
}
.logo-img {
  min-width: 100px;
  max-width: 151px;
  margin-right: 2rem;
}
.rounded-profile {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  font-size: fontsize('s16');
  line-height: fontsize('s24');
  color: #00a7b7;
  background-color: #f2fbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.rounded-collab {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: fontsize('s16');
  line-height: fontsize('s24');
  color: #00a7b7;
  background-color: #f2fbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.rounded-profile-sm {
  height: 32px;
  width: 32px;
  border-radius: 200px;
  color: #00a7b7;
  background-color: #f2fbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px !important;
}
.rounded-profile-x-sm {
  height: 24px;
  width: 24px;
  border-radius: 200px;
  color: #00a7b7;
  background-color: #f2fbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px !important;
  &__bordered {
    border: 1.5px solid #ffffff;
  }
}
.stacked-Images-rounded {
  display: flex;
  justify-content: flex-start;
  img:not(:first-child),
  span:not(:first-child),
  div:not(:first-child) {
    margin-left: -8px;
  }
}
.rounded-avatar-bordered {
  height: 24px;
  width: 24px;
  border-radius: 200px;
  border: 1.5px solid #ffffff;
  object-fit: cover;
}
.navbar-brand {
  margin: 0;
}
#collasible-nav-dropdown.nav-link {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#collasible-nav-dropdown::after {
  border: none;
}
.accordion {
  color: font_clr(d-grey-600);
  .accordion-body {
    padding: 0px;
  }
  .accordion-button {
    border: 1px solid #b692f6;
    color: font_clr(d-grey-600);
    padding: 12px;
    &:focus {
      box-shadow: none;
    }
  }
  .accordion-button::after {
    background-image: url('./../public/Images/upArrow.svg') !important;
    margin: 0;
    padding: 0;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 5%;
    background-size: auto;
    transition: all 0.2s ease-in-out;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url('./../public/Images/upArrow.svg') !important;
    transform: rotate(180deg) !important;
  }
  .accordion-button:not(.collapsed) {
    background: #f9f5ff;
    box-shadow: none;
  }
}
.suggestions-accordion {
  .accordion-item {
    border: none !important;
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-radius: 8px !important;
    }
    .accordion-button {
      border-radius: 8px !important;
    }
  }
}
.nav-link {
  color: font_clr('black') !important;
}

@media screen and (min-width: 768px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
    top: 56px;
  }
}
.backdropCustomWhite {
  background-color: #fff !important;
  opacity: 1 !important;
}
.fixwidthModal {
  &_476px {
    border: none;
    border-radius: 14px;
    max-width: 476px;
    min-height: auto !important;
    box-shadow:
      0px 20px 24px -4px rgba(16, 24, 40, 0.1),
      0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    .modal-content {
      border: none;
    }
  }
}

.modal-680 {
  min-width: 680px !important;
  .modal-content {
    @include modal-sytles;
  }
}
.modal-899 {
  min-width: 899px;
  .modal-content {
    @include modal-sytles;
  }
}
.modal-600 {
  min-width: 600px !important;
  @media screen and (min-width: 340px) and (max-width: 768px) {
    min-width: 95% !important;
  }
  .modal-content {
    @include modal-sytles;
  }
}
.PickATemplateModal {
  .modal-content {
    min-width: 896px;
    min-height: 600px;
  }
  .tab-content {
    margin-top: 16px;
    min-height: 319px;
  }
  .nav-link.active {
    position: relative;
    background: transparent !important;
    .title_image {
      svg {
        filter: none;
      }
    }
    p {
      color: #101828;
    }
    &::after {
      content: url('./../public/Images/rect.svg');
      position: absolute;
      bottom: -36px;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .Articles .active .title_image {
    border-color: #8b6efe;
  }
  .Descriptions .active .title_image {
    border-color: #53b1fd;
  }
  .Emails .active .title_image {
    border-color: #40a9a5;
  }
  .PressReleases .active .title_image {
    border-color: #7f56d9;
  }
  .PressRelease .active .title_image {
    border-color: #7f56d9;
  }
  .Captions .active .title_image {
    border-color: #f670c7;
  }
  .AdCopy .active .title_image {
    border-color: #f670c7;
  }
  .Letters .active .title_image {
    border-color: #6172f3;
  }
  .SocialMedia .active .title_image {
    border-color: #6172f3;
  }
  .Pitches .active .title_image {
    border-color: #4e5ba6;
  }
  .EmailCampaigns .active .title_image {
    border-color: #4e5ba6;
  }
  .Articles .template-card .active {
    border-color: #8b6efe !important;
  }
  .Descriptions .template-card .active {
    border-color: #53b1fd !important;
  }
  .Emails .template-card .active {
    border-color: #40a9a5 !important;
  }
  .PressReleases .template-card .active {
    border-color: #7f56d9 !important;
  }
  .PressRelease .template-card .active {
    border-color: #7f56d9 !important;
  }
  .AdCopy .template-card .active {
    border-color: #f670c7 !important;
  }
  .Captions .template-card .active {
    border-color: #f670c7 !important;
  }
  .Letters .template-card .active {
    border-color: #6172f3 !important;
  }
  .SocialMedia .template-card .active {
    border-color: #6172f3 !important;
  }
  .EmailCampaigns .template-card .active {
    border-color: #4e5ba6 !important;
  }
  .Pitches .template-card .active {
    border-color: #4e5ba6 !important;
  }
}
.profile-section {
  .name {
    @include text-md-Regular;
    color: #344054;
    &.text-fonts-name {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #101828;
      font-family: 'Poppins';
    }
  }
  .name-dark {
    color: #000000;
  }
  .link {
    @include text-md-Regular;
    color: #344054;
    &.draft-link {
      font-size: 14px !important;
      font-weight: 500;
      color: #00a7b7 !important;
      line-height: 20px;
      font-family: 'DM Sans';
    }
  }
  gap: 10px;
}
.badge-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 2px 8px 2px 8px;
  border-radius: 16px;
  background-color: #f2f4f7;
  width: fit-content;
  span {
    @include text-md;
  }
}
.badge-wrapper.active {
  @include text-sm-lh18;
  color: #7f56d9;
  background-color: #efe9f6;
}
.InReviewBadge {
  color: #800080;
  background-color: #fbe1fb;
}
.InProgressBadge {
  color: #2e90fa;
  background-color: #dbe8f7;
}
.NotStartedBadge {
  color: #f63804;
  background-color: #f5e3de;
}
.PublishedBadge {
  color: #f5dc05;
  background-color: #f7f7f7;
}
.ApprovedBadge {
  color: #458201;
  background-color: #ebf8dc;
}
.LowBadge {
  color: #5bb46a;
  background-color: #ebfbf3;
  display: flex;
  align-items: center;
  gap: 9px;
}
.MediumBadge {
  color: #f59008;
  background-color: #fffaeb;
  display: flex;
  align-items: center;
  gap: 9px;
}
.HighBadge {
  color: #ee5237;
  background-color: #fdf3f2;
  display: flex;
  align-items: center;
  gap: 9px;
}
.btn-primary {
  background: transparent;
  border: none;
  padding: 0 !important;
  margin: 0;
  &:focus-visible,
  :focus-within,
  :focus,
  :active {
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:hover {
    // background: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }
}
.btn-primary:hover {
  background: transparent;
}
.profileDropDownBtn {
  .btn-primary {
    &:hover {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  .dropdown-menu.show {
    min-width: 240px;
  }
}

.select-options.form-check-input:checked[type='checkbox'] {
  width: 20px !important;
  background-color: #f2fbfb;
  border-color: #00a7b7;
  padding: 4px !important;
  background-image: url('../public/Images/check-sm.svg') !important;
  background-position: center !important;
  background-size: contain !important;
}
.form-check-input:checked[type='checkbox'] {
  height: 20px;
  background-color: #00a7b7;
}
.form-check-input[type='checkbox'] {
  border-color: #00a7b7;
  height: 20px;
}
.form-switch .form-check-input {
  background-image: url('../public/Images/switch-bg-default.svg') !important;
}
.form-switch .form-check-input:checked {
  background-image: url('../public/Images/switchbg.svg') !important;
}
.form-check-input:focus {
  box-shadow: none;
}
.input-group {
  .btn:disabled {
    background-color: inherit;
    color: #000;
    background-color: #d0d5dd;
  }
  input {
    &:focus,
    :focus-visible,
    :focus-within {
      box-shadow: none !important;
    }
  }
}
.btn {
  font-family: 'Poppins';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 12px;
  padding: 10px 16px;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  &:disabled {
    border: 1px solid #000;
    cursor: not-allowed;
  }

  &__defaultStyles {
    color: font_clr('d-grey');
    &:hover {
      color: font_clr('d-grey');
      background: transparent;
    }
  }
  &:active {
    background-color: #f9f5ff !important;
    color: font_clr('d-grey') !important;
    border-color: inherit !important;
    svg {
      // fill: font_clr("d-grey") !important;
    }
  }
  &:focus-visible {
    box-shadow: none;
  }
}
.btn.show {
  background-color: transparent !important;
  color: #00a7b7;
  svg {
    stroke: #00a7b7;
    path {
      stroke: #00a7b7;
    }
  }
}
ul {
  li {
    list-style: none;
  }
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  color: font_clr(d-grey);
}
.getStartedBtn {
  font-family: 'Poppins';
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 28px;
  border-radius: 40px;
  background: transparent;
  border: 1px solid font_clr('black');
  transition: all 0.5s;
  font-size: 18px;
  @media screen and (min-width: 340px) and (max-width: 992px) {
    padding: 8px 12px;
    font-size: 12px;
    span {
      font-size: 12px !important;
    }
  }

  &:hover {
    background-color: font_clr('black');
    color: font_clr('white');
  }
}
.Footer_links.nav-link {
  color: #344054 !important;
  @media screen and (min-width: 340px) and (max-width: 768px) {
    font-size: 12px;
  }
}

.Planner__Pills {
  @media screen and (min-width: 340px) and (max-width: 768px) {
    .tab-content > .tab-pane {
      text-align: center !important;
    }
  }
  .nav-tabs {
    border: none;
    background-color: #fff;
    border-radius: 8px;
    padding: 4px;
    width: fit-content;
    @media screen and (min-width: 340px) and (max-width: 768px) {
      width: 100% !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
  .nav-link {
    background: transparent;
    border-radius: 8px;
    font-weight: 600;
    color: #475467;
    border: none;
    filter: grayscale(50);
    &:hover {
      background: #f9f5ff;
      border-radius: 8px;
      border: none;
    }
    &:focus,
    &:active {
      outline: none;
      border: none;
    }
  }
  .nav-link.active {
    color: #6941c6 !important;
    filter: grayscale(0);
    background: #f9f5ff;
    border-radius: 8px;
    border: none;
  }
}
.w-700 {
  font-weight: 700 !important;
}
.add-sticky {
  position: sticky !important;
  top: 0;
  z-index: 10;
}
.ms-13 {
  margin-left: 13px !important;
}
.pl-117 {
  padding-left: 117px !important;
  @media screen and (max-width: 992px) {
    padding-left: 24px !important;
  }
}
.pl-87 {
  padding-left: 87px !important;
  @media screen and (max-width: 992px) {
    padding-left: 24px !important;
  }
}
.w-87 {
  width: 87% !important;
  @media screen and (max-width: 992px) {
    width: 137% !important;
  }
}
.animation {
  transition: all 0.5s;
}

.HeaderIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 99px;
  transition: all 0.5s;
  border-radius: 8px;
  img {
    filter: brightness(0%);
  }
  &:hover {
    cursor: pointer;
    img {
      filter: brightness(100%);
    }
  }
}

.carot {
  height: 8px;
  width: 4px;
}
.activeIcon {
  filter: brightness(100%) !important;
  stroke: #7f56d9 !important;
}
.dropend .dropdown-toggle::after {
  display: none;
}
.Dropdown-Button {
  .btn.show {
    color: #fff !important;
  }
  &__title {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
  .dropdown-toggle {
    padding: 8px 12px;
    &:active {
      background-color: #000 !important;
      color: #fff !important;
      svg {
        fill: #fff !important;
      }
    }
    &::after {
      display: block;
    }
  }
}
.dropdown,
.dropend,
.dropstart {
  .btn {
    &:hover {
      background-color: transparent;
    }
  }
}

.dropdown-toggle::before {
  display: none !important;
}
.dropstart {
  .dropdown-menu.show {
    transform: translate3d(0px, 28px, 0px) !important;
  }
}
.dropdown-toggle::after {
  display: none;
}
.toast {
  .btn-close {
    display: none;
  }
}
.plannerPills {
  .nav-link.active {
    border-bottom: none !important;
  }
  .nav-link {
    padding: 8px 16px !important;
  }
}

.icon {
  cursor: pointer;
}
.reactSelectClass {
  &:first-child {
    div:first-child {
      img {
        display: none;
      }
    }
  }
}
.headerSelect {
  height: 100%;
  z-index: 99;
  &__control {
    border-radius: 16px !important;
    color: #344054;
    background-color: #f2f4f7 !important;
    min-width: 175px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: #d0d5dd !important;
  }
  &__value-container {
    padding: 0;
  }
  &__indicator {
    padding: 4px !important;
    color: #000 !important;
    margin-right: 4px !important;
  }
  &__single-value {
    color: #1d2939 !important;
    @include text-md;
  }
  &__indicator-separator {
    display: none;
  }
}

.SaveFolderSelect {
  &__control {
    min-height: 44px !important;
    box-shadow: none !important;
    border-color: #d0d5dd !important;
  }
  &__value-container--has-value {
    padding: 0 !important;
  }
  &__indicator-separator {
    display: none;
  }
}
.tooltip-white {
  background-color: #fff;
  opacity: 1 !important;
  .tooltip-arrow {
    &:before {
      border-top-color: #ffffff;
    }
  }
  .tooltip-inner {
    @include text-sm-lh18;
    width: 193px;
    text-align: left;
    box-shadow:
      0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    padding: 8px 12px;
    background: #ffffff;
    opacity: 1 !important;
    border-radius: 8px;
    color: #000;
  }
}

// ===========  Planner Card borders ================

// .Card__Inprogress {
//   border-left: 2px solid #2e90fa;
// }
// .Card__Inreview {
//   border-left: 2px solid #800080;
// }
// .Card__NotStarted {
//   border-left: 2px solid #f63804;
// }
// .Card__Published {
//   border-left: 2px solid #f5dc05;
// }
// .Card__Approved {
//   border-left: 2px solid #458201;
// }
.deadline-alert {
  @include text-md-Regular;
  color: #f79009;
}
.content-approach-wrapper {
  @media screen and (min-width: 340px) and (max-width: 768px) {
    align-items: center !important;
    text-align: center !important;
    h2 {
      font-size: 19px !important;
      text-align: center !important;
      align-items: center;
    }
    span {
      font-size: 24px;
      text-align: center !important;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 0px 0px 32px 0px !important;
    h2 {
      gap: 0px !important;
      padding: 16px !important;
    }
  }
}
.research-keyword {
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 50%;
  }
}

.modalPlanConfirmation {
  .modalWidth__400 {
    max-width: 400px !important;
  }
  .modal-content {
    border-radius: 12px;
    background: #fff;
    box-shadow:
      0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  }
  .modalBody {
    overflow: hidden;
    position: relative;
    background-image: url(../public/Images/backgroundPatternModal.svg);
    background-repeat: no-repeat;
    background-position: top left;
    .modal__iconsWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .confrimIcon {
        min-width: 48px;
        max-width: 48px;
        min-height: 48px;
        max-height: 48px;
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 8px solid #ecfdf3;
        background: #dcfae6;
        img {
          height: 24px;
          width: 24px;
        }
      }
      .closeIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 44px;
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }
    .headingWrapper {
      .heading {
        color: #101828;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 4px;
      }
      .desc {
        color: #475467;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .modalFooter {
    padding: 24px;
    padding-top: 32px;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .modalBtn {
      &,
      &:focus,
      &:active {
        background-color: transparent;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 10px 18px;
      }
    }
    .grayOutlinedBtn {
      &,
      &:focus,
      &:active {
        border: 1px solid #d0d5dd;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #344054;
      }
    }
    .blackBtn {
      &,
      &:focus,
      &:active {
        border: 1px solid #000;
        background: #000;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #fff;
      }
    }
  }
}
.modalPlanRenew {
  .modalWidth__600 {
    max-width: 600px !important;
    .modal-height {
      min-height: 163px !important;
    }
    .modal-content {
      border-radius: 12px;
      background: #fff;
      box-shadow:
        0px 8px 8px -4px rgba(16, 24, 40, 0.04),
        0px 20px 24px -4px rgba(16, 24, 40, 0.1);
      min-height: 462px;
    }
    .modalBody {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 32px;
      .modalHeadingWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        .heading {
          color: #2c3e50;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
        }
        .closeIcon {
          margin-left: 10px;
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
          cursor: pointer;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
      .btnWrapper {
        display: flex;
        width: 100%;
        margin-top: auto;
        .modalBtn {
          &,
          &:focus,
          &:active {
            background-color: transparent;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 10px 18px;
          }
        }
        .blackBtn {
          &,
          &:focus,
          &:active {
            border: 1px solid #000;
            background: #000;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: #fff;
          }
        }
      }
    }
  }
}

.media-node-view {
  display: flex;
  position: relative;

  &.f-left {
    float: left;
  }

  &.f-right {
    float: right;
  }

  &.align-left {
    justify-content: left;
  }

  &.align-center {
    justify-content: center;
  }

  &.align-right {
    justify-content: right;
  }

  .horizontal-resize-handle,
  .vertical-resize-handle {
    position: absolute;
    opacity: 0.5;
    z-index: 50;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: blue;
    }
  }

  .horizontal-resize-handle {
    height: 100%;
    width: 2px;
    top: 0;
    right: 0;
    cursor: col-resize;
  }

  .vertical-resize-handle {
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    cursor: row-resize;
  }
}

.image-actions-container {
  display: flex;
  gap: 10px;
  .image-action-button {
    background-color: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 4px 8px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #f9f5ff;
    }
  }
}

.primary_btn {
  background-color: white;
  border: 1px solid #1d2939;
  color: #1d2939;
  font-family: 'Poppins';
  border-radius: 8px;
  &:hover {
    background-color: #fbb956;
    color: #1d2939;
    border: 1px solid #fbb956;
  }
  &:disabled {
    background: #d0d5dd;
    color: #fff;
    border-color: #d0d5dd;
  }
}

.secondary_btn {
  background-color: #1d2939;
  border: 1px solid #1d2939;
  color: #fff;
  font-family: 'Poppins';
  border-radius: 8px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 14px;
  padding: 10px 16px;
  &:hover {
    background-color: #1d2939 !important;
    border: 1px solid #1d2939;
    color: #fff;
  }
  &:active {
    background-color: #1d2939 !important;
    border: 1px solid #1d2939 !important;
    color: #fff !important;
  }
  &:disabled {
    background: #d0d5dd;
    color: #fff;
    border-color: #d0d5dd;
  }
}

.btn_padding {
  padding: 10px 16px !important;
}

.light_btn {
  border-radius: 8px;
  border: 1px solid #f2fbfb;
  background: #f2fbfb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #00a7b7;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  // padding: 10px 16px !important;

  &:hover {
    background-color: #00a7b7;
    border: 1px solid #00a7b7;
    color: #fff;
  }
  &:active {
    background-color: #00a7b7 !important;
    border: 1px solid #00a7b7 !important;
    color: #fff !important;
  }
  &:disabled {
    background: #d0d5dd;
    color: #fff;
    border-color: #d0d5dd;
  }
}

.white_btn {
  background-color: #fff;
  border: 1px solid #d0d5dd;
  color: #344054;
  font-family: 'Poppins';
  border-radius: 8px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 14px;
  // padding: 10px 16px !important;
  &:hover {
    background-color: #fff;
    border: 1px solid #d0d5dd;
    color: #344054;
  }
  &:active {
    background-color: #fff !important;
    border: 1px solid #d0d5dd !important;
    color: #344054 !important;
  }
  &:disabled {
    background: #d0d5dd;
    color: #fff;
    border-color: #d0d5dd;
  }
}

.cancel_btn {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--colors-gray-light-mode-300, #d0d5dd);
  background: var(--neutral-colors-black-white-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(
    --neutral-colors-Gray-neutral-900,
    var(--colors-gray-light-mode-900, #101828)
  );
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  // padding: 10px 16px !important;
  &:hover {
    background-color: #fff;
    border: 1px solid #d0d5dd;
    color: #344054;
  }
  &:active {
    background-color: #fff !important;
    border: 1px solid #d0d5dd !important;
    color: #344054 !important;
  }
  &:disabled {
    background: #d0d5dd;
    color: #fff;
    border-color: #d0d5dd;
  }
}
.table_btn {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--colors-gray-light-mode-300, #d0d5dd);
  background: var(--Colors-Base-white, #fff);
  color: var(--Brand-Colors-Secondary-Moonstone-900, #00a7b7);
  text-align: center;

  /* Body/xs/Bold */
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */

  &:disabled {
    background: #d0d5dd;
    color: #fff;
    border-color: #d0d5dd;
  }
}

// stripe
.StripeElement {
  display: block;
  margin: 10px 0;
  font-family: 'Poppins';
  font-weight: 400;
  border-radius: 8px;
  padding: 8px 0;
  line-height: 24px;
  font-size: 16px;
  color: #101828;
}

.StripeElement iframe {
  width: 100%;
  height: 45px;
  border: none;
  padding: 5px;
}

.StripeElement .InputElement {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  font-size: 16px;
}
.css-b62m3t-container {
  .css-13cymwt-control {
    padding: 2px 4px !important;
    border-radius: 8px !important;
    font-family: 'DM Sans' !important;
    font-size: 14px;
  }
  .css-t3ipsp-control {
    padding: 2px 4px !important;
    border-radius: 8px !important;
    font-size: 14px;
    border-color: #00a7b7 !important;
    box-shadow:
      0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px #00a8b715 !important;
  }
}
.text-area-height {
  min-height: 108px !important;
}

.AITool_CardSection__Card_Icon__necrD {
  svg {
    stroke: #00a7b7 !important;
  }
}

.dropdown_container {
  .Personal-View-Select__control {
    border-radius: 8px;
    min-height: 40px;
    min-width: 138px;
  }
}

.form-label {
  margin-bottom: 6px;
}

//
.css-1ff06q0-control {
  background-color: transparent !important;
  // border-radius: 16px !important;
  border: 1px solid var(--colors-gray-light-mode-300, #d0d5dd) !important;
  color: var(
    --neutral-colors-Gray-neutral-700,
    var(--colors-gray-light-mode-700, #344054)
  ) !important;
  // text-align: center !important;
  font-family: 'Poppins';
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.Custom-Select {
  .css-1ff06q0-control {
    border: none !important;
  }
}

// Drawer Footer

.drawer-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 12px 18px;
  border-top: 1px solid #eaecf0;
}

.drawer-progress-bar {
  background-color: #eaecf0;
  background: #eaecf0;
  height: 4px;
  position: absolute;
  // top: 92.5%;
  bottom: 65px;
  width: 100%;
  z-index: 1;
}

.progress-bar {
  background-color: #00a7b7;
}

.pre-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  width: 20px;
  display: flex;
  svg {
    height: 100%;
    width: 100%;
  }
}

.preicon_input {
  padding-left: 40px !important;
}
.post-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  width: 20px;
  display: flex;
  cursor: pointer;
  svg {
    height: 100%;
    width: 100%;
  }
}

.posticon_input {
  padding-right: 40px !important;
}

.react-international-phone-input {
  flex-grow: 1;
  height: 42px !important;
  border-radius: 0 8px 8px 0 !important;
  font-family: 'DM Sans' !important;
  font-size: 14px !important;
  &:focus {
    border: 1px solid #00a7b7 !important;
    box-shadow:
      0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(0, 168, 183, 0.0823529412);
  }
}

.react-international-phone-country-selector-button {
  height: 42px !important;
  border-radius: 8px 0 0 8px !important;
}
.btn-dark.btn:active {
  background-color: #1d2939 !important;
}
.infinite-scroll-component > div.d-flex {
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #00a7b7;
  }
}
.MediumBadge {
  display: none;
}

.btn-dark.btn.show {
  color: #fff;
  background: radial-gradient(#1d2939, #1d2939);
  svg {
    stroke: #fff;
    path {
      stroke: #fff;
    }
  }
}

.pre-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  width: 20px;
  display: flex;
  svg {
    height: 100%;
    width: 100%;
  }
}

.preicon_input {
  padding-left: 40px !important;
}
.post-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  width: 20px;
  display: flex;
  cursor: pointer;
  svg {
    height: 100%;
    width: 100%;
  }
}

.posticon_input {
  padding-right: 40px !important;
}

.react-international-phone-input {
  flex-grow: 1;
  height: 42px !important;
  border-radius: 0 8px 8px 0 !important;
  font-family: 'DM Sans' !important;
  font-size: 14px !important;
  &:focus {
    border: 1px solid #00a7b7 !important;
    box-shadow:
      0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(0, 168, 183, 0.0823529412);
  }
}

.react-international-phone-country-selector-button {
  height: 42px !important;
  border-radius: 8px 0 0 8px !important;
  padding: 0 10px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

td {
  padding: 0 !important;
}
.table-sticky {
  position: relative;
  tr {
    th,
    td {
      white-space: nowrap;
      &:first-child {
        left: 0px;
        z-index: 1;
      }
    }
    td {
      background-color: white;
    }
  }
}
@media (min-width: 992px) {
  .table-sticky {
    th,
    td:first-child {
      position: sticky;
    }
  }
}
.email-w-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  gap: 6px !important;
  .icon-wrapper {
    padding: 0px;
    position: absolute;
    left: 14px;
    top: 11px;
  }
  .input {
    padding: 0px !important;

    .form-control {
      padding: 10px 10px 10px 38px !important;
      background-color: transparent;
      height: 40px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .Custom-Select__control {
    margin-right: 10px;
    border: none !important;
    .Custom-Select__value-container {
      padding: 0px;
    }
    .Custom-Select__indicators {
      max-height: 40px;
      margin-top: 2px;
      svg {
        color: #101828 !important;
      }
    }
    .Custom-Select__placeholder {
      color: #98a2b3;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.p-common {
  padding: 20px 24px;
}
.tabs-wrapper {
  .nav-link {
    border: none;
    &.active {
      border-bottom: 1px solid #00a7b7;
    }
  }
}

.gap-table-data {
  gap: 6px;
}

.offcanvas-header {
  .btn-close {
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
.product-tour-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}
.content-type-container {
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}
.Accordion_custom_nav {
  padding: 0px 14px 0px 18px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 18px;
    height: 100%;
    width: 1px;
    display: block;
    background-color: #334155;
  }
}
.dropdown_container {
  z-index: 5;
}

nav > .breadcrumb {
  margin-bottom: 0 !important;
}

.Projects-Library-Tabs_campaigns {
  & > li {
    & > button {
      &:focus-visible {
        border-bottom: 2px solid !important;
      }
      padding: 16px 4px !important;
      margin-bottom: 0 !important;
    }
  }
}
